export default function Home() {
  return (
    <div className="App">
      <main className="App-main">
        <div className="highlight-video">
          <div className="video-container">
            <h1>Search silently and fast</h1>
            <video muted autoPlay loop playsInline>
              <source src="prev.mp4" />
            </video>
          </div>
        </div>
        <div className="brand-list">
          <h1 className="title">Brands</h1>
          <ul className="scrollable list">
            <Item link={"/#"} img={"/rand.jpg"} name={"Tesla"} />
            <Item link={"/#"} img={"/b6.jpg"} name={"BYD"} />
            <Item link={"/#"} img={"/aaa.png"} name={"NIO"} />
            <Item link={"/#"} img={"/rand.jpg"} name={"Lucid"} />
            <Item link={"/#"} img={"/rand.jpg"} name={"Rivian"} />
            <Item link={"/#"} img={"/rand.jpg"} name={"Xpeng"} />
            <Item link={"/#"} img={"/rand.jpg"} name={"Polestar"} />
            <Item link={"/#"} img={"/rand.jpg"} name={"Faraday Future"} />
          </ul>
          <button
            id="prevBtn"
            onClick={(e) => {
              e.target.parentElement
                .querySelector("ul")
                .scrollBy({ left: -200, behavior: "smooth" });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-left-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1" />
            </svg>
          </button>
          <button
            id="nextBtn"
            onClick={(e) => {
              e.target.parentElement
                .querySelector("ul")
                .scrollBy({ left: +200, behavior: "smooth" });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-right-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
            </svg>
          </button>
        </div>
        <div className="latest-product-list">
          <h1 className="title">The Latest Automobiles</h1>
          <ul className="product-list">
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
            <li className="item">
              <Card
                img={"car.png"}
                name={"BYD Yangwang U8"}
                price={"$0"}
                id={1}
              />
            </li>
          </ul>
        </div>
      </main>
    </div>
  );
}
function Item({ link, img, name }) {
  return (
    <li className="item">
      <a href={link}>
        <img src={img} alt="brand" />
        <p style={{ margin: 0 }}>{name}</p>
      </a>
    </li>
  );
}

function Card({ img, name, price, id }) {
  return (
    <a href={`product/${id}`}>
      <div className="card">
        <img src={img} className="card-img-top" alt="..." />
        <div className="card-body">
          <a href={`product/${id}`} className="card-title">
            <h3>{name}</h3>
          </a>
          <p className="card-text">Short description</p>
          <a href={"product/" + id} className="btn btn-secondary see-more-link">
            See more
          </a>
        </div>
      </div>
    </a>
  );
}
