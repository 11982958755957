import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
export default function ProductList() {
  return (
    <div className="App-products">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "10px",
          boxSizing: "border-box",
        }}
      >
        <h1 className="title">Products</h1>
        <Dropdown />
      </div>
      <ul className="product-list">
        <li className="item">
          <Card img={"/car.png"} name={"Model S"} price={"$100,000"} />
        </li>
        <li className="item">
          {" "}
          <Card img={"/car.png"} name={"Model 3"} price={"$50,000"} />
        </li>
        <li className="item">
          {" "}
          <Card img={"/car.png"} name={"Model X"} price={"$120,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Model Y"} price={"$60,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Cybertruck"} price={"$40,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Roadster"} price={"$200,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Model S"} price={"$100,000"} />
        </li>
        <li className="item">
          {" "}
          <Card img={"/car.png"} name={"Model 3"} price={"$50,000"} />
        </li>
        <li className="item">
          {" "}
          <Card img={"/car.png"} name={"Model X"} price={"$120,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Model Y"} price={"$60,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Cybertruck"} price={"$40,000"} />
        </li>
        <li className="item">
          <Card img={"/car.png"} name={"Roadster"} price={"$200,000"} />
        </li>
      </ul>
    </div>
  );
}
function Card({ img, name, price }) {
  return (
    <div className="card">
      <img src={img} className="card-img-top" alt="..." />
      <div className="card-body">
        <a href="#" className="card-title">
          <h3>{name}</h3>
        </a>
        <p className="card-text">Short description</p>
        <a href="#" className="see-more-link">
          See more
        </a>
      </div>
    </div>
  );
}

function Dropdown() {
  return (
    <div className="dropdown">
      <button
        className="btn dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-sliders"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"
          />
        </svg>
      </button>
      <ul className="dropdown-menu">
        <li>
          <a className="dropdown-item" href="#">
            Tesla
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Toyota
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#">
            Volvo
          </a>
        </li>
      </ul>
    </div>
  );
}
