import './product.css';
export default function Product() {
  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    color: "black",
    borderRadius: "15px",
  };
  return (
    <div className="App-main">
      <div className="preview image">
        <img src="/car.png" alt="Product Preview" />
      </div>
      <h1 className="title">New Sinotruk HOWO 4×2 Garbage Compactor Truck</h1>
      <hr></hr>
      <p className="description">
        The HOWO 4×2 Garbage compactor truck features various functions
        including garbage collection, automatic loading and compacting of
        garbage, garbage transfer, and dumping. It is primarily suitable for
        collecting and transferring packaged, bagged, and loose household
        garbage in urban residential areas, communities, large factories, mines,
        and narrow streets in old city districts.
      </p>
      <button style={buttonStyle}>Send Inquiry</button>

      <h2 className="title">Product Introduction</h2>
      <h3 className="subtitle">Sinotruk HOWO 4×2 Garbage compactor truck</h3>
      <p>
        The HOWO 4×2 Garbage compactor truck features a customizable box volume,
        with a single-body compartment constructed with 4mm side panels and 5mm
        bottom panels made of Q345 high-strength steel. The push shovel, filler,
        scraper, skid plate, and secondary beam are all made of Q345B material.
        The truck comes with both manual and electric hydraulic controls, with a
        rear tipping mechanism equipped with a hanging bucket device (compatible
        with two 120L garbage bins or one 660L garbage bin). It also includes a
        hydraulic sealed cover, a filler of 1.8 cubic meters, and front/rear
        sewage tanks of 300L/500L respectively. It is equipped with a PTO (power
        take-off) device, a Jia Cheng multi-way valve, Jia Cheng controller, and
        Mach sensors. The compaction is hydraulic, with metal mudguards, blank
        mudguards, and a ladder installed on one side of the box. It also
        features side protection; the cross-section height is less than or equal
        to 100mm, and the height from the bottom edge to the ground is less than
        or equal to 550mm. The working cycle time for the lifting device is ≤
        15s, unloading cycle time is ≤ 45s, and the compression cycle time is ≤
        15s.
      </p>

      <h3 className="title">Sinotruk HOWO 4×2 Garbage compactor truck</h3>
      <p>
        The HOWO 4×2 Garbage compactor truck features a customizable box volume,
        with a single-body compartment constructed with 4mm side panels and 5mm
        bottom panels made of Q345 high-strength steel. The push shovel, filler,
        scraper, skid plate, and secondary beam are all made of Q345B material.
        The truck comes with both manual and electric hydraulic controls, with a
        rear tipping mechanism equipped with a hanging bucket device (compatible
        with two 120L garbage bins or one 660L garbage bin). It also includes a
        hydraulic sealed cover, a filler of 1.8 cubic meters, and front/rear
        sewage tanks of 300L/500L respectively. It is equipped with a PTO (power
        take-off) device, a Jia Cheng multi-way valve, Jia Cheng controller, and
        Mach sensors. The compaction is hydraulic, with metal mudguards, blank
        mudguards, and a ladder installed on one side of the box. It also
        features side protection; the cross-section height is less than or equal
        to 100mm, and the height from the bottom edge to the ground is less than
        or equal to 550mm. The working cycle time for the lifting device is ≤
        15s, unloading cycle time is ≤ 45s, and the compression cycle time is ≤
        15s.
      </p>
      <hr></hr>
      
    </div>
  );
}
