function InquiryPage() {
    const btnStyle = {
        borderRadius: '5px',
        width: '120px'
    }
    const label = {
       display: 'block'
    }
    const input ={
       width: '100%',
       marginBottom: '20px'
    }
  return (
    <>
      <div style={{ padding:'30px 0', width: "100%", display:"flex", justifyContent: "center"}}>
        <form
          style={{
            width: "95%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <fieldset>
            <legend>Inquiry:</legend>
            <p>Our sales representative will contact you within 24 hours</p>
            <label style={label} for="name">Name </label>
            <input style={input} type="text" id="name" name="name"></input>

            <br></br>
            <label style={label} for="email">Email </label>
            <input style={input} type="email" id="email" name="email"></input>
            <br></br>
            <label style={label} for="content">Inquiry </label>
            <textarea rows={5} style={input} type="text" id="content" name="content"></textarea>
          </fieldset>
          {/* <input style={{display:"inline-block"}} type="text"></input>
      <input style={{display:"inline-block"}}></input>
      <textarea style={{display:"inline-block"}}></textarea> */}
          <button style={btnStyle}>Submit</button>
        </form>
      </div>
    </>
  );
}


export default InquiryPage;