import "./about.css";

export default function About() {
  return (
    <div className="about" style={{padding: '10px'}}>
      <div className="title-cont">
        <h1 className="title">Company Profile</h1>
      <p className="underline">______</p>
      </div>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd is a legal export company, which
        registered in China, and has legal export qualification.
      </p>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd. It is an honorary member of Jining
        E-commerce Chamber of Commerce in Shandong Province. Jining Friendly Auto Trading Co.,Ltd is a legal export company, which
        registered in China, and has legal export qualification.
      </p>
      <div className="title-cont">
        <h1 className="title">Our factory</h1>
      <p className="underline">______</p>
      </div>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd is a legal export company, which
        registered in China, and has legal export qualification.
      </p>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd. It is an honorary member of Jining
        E-commerce Chamber of Commerce in Shandong Province.
      </p>
      <div className="title-cont">
        <h1 className="title">Company Profile</h1>
      <p className="underline">______</p>
      </div>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd is a legal export company, which
        registered in China, and has legal export qualification.
      </p>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd. It is an honorary member of Jining
        E-commerce Chamber of Commerce in Shandong Province.
      </p>
      <div className="title-cont">
        <h1 className="title">Our factory</h1>
      <p className="underline">______</p>
      </div>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd is a legal export company, which
        registered in China, and has legal export qualification.
      </p>
      <p className="description">
        Jining Friendly Auto Trading Co.,Ltd. It is an honorary member of Jining
        E-commerce Chamber of Commerce in Shandong Province.
      </p>
      
    </div>
  );
}
